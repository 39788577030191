<template>
  <div class="pages">
    <el-timeline>
      <el-timeline-item
          v-for="(item, index) in list"
          :key="index"
          :timestamp="item.date"
          placement="top"
      >
          <p class="title">{{item.title}}</p>
          <p class="description">{{item.description}}</p>
      </el-timeline-item>
    </el-timeline>
  </div>
</template>

<script>
let map = null // eslint-disable-line
export default {
  name: 'HousesDynamic',
  data() {
    return {
      list:[]
    }
  },
  async mounted() {
    await this.fetchData()
  },
  methods: {
    async fetchData() {
      let res = await this.http({
        url: '/api/report/projectDynamic',
        method: 'GET',
        params: {
          project_id: this.$route.query.id
        }
      })

      this.list = res.data
    },
  }
}
</script>

<style scoped lang="scss">
@import "HousesDynamic";
</style>
